<template>
  <div id="userOrder">
    <HomePageTop ref="tyop"></HomePageTop>
    <div class="collectionContainer">
      <!--      仓库切换-->
      <div class="stickyContainer">
        <div class="flex orderScreenContainer">
          <div class="orderScreenLeft flexAndCenter">
            <div v-for="(item,index) in addressSwitchover" :key="index"
                 :class="switchoverIndex===index?'activeSwitchover flexAndCenterAndCenter':' flexAndCenterAndCenter'"
                 @click="switchoverWarehouse(item,index)">
              {{ item.label }}
              <el-popover trigger="hover" placement="top" v-if="item.value==='装箱中'">
                <div>
                  配送委託された商品は現在梱包中です。<br>
                  お急ぎの場合は担当者までご連絡ください
                </div>
                <img src="../../../../assets/4.png" slot="reference" style="margin-top: 15px;">
              </el-popover>
            </div>
          </div>
          <div class="fontSize14 colorB4272B" v-show="switchoverIndex===1" style="padding-top: 10px">*
            {{ $fanyi("收费仓库里货物需要发货，请直接与您的负责人联系！") }}
          </div>
          <div v-show="switchoverIndex===0" class="orderScreenRight">
            <div class="flexAndCenter">
              <el-select v-if="employeesAccountList.length>0" v-model="children_id" size="mini"
                         :placeholder="$fanyi('员工账户选择')" clearable
                         style="margin-right: 10px"
                         @change="getStockAutoList">
                <el-option
                    v-for="item in employeesAccountList"
                    :key="item.children_user_id"
                    :label="item.children_realname"
                    :value="item.children_user_id">
                </el-option>
              </el-select>
              <el-popover trigger="hover" placement="bottom">
                <div>エクセル導出</div>
                <img src="../../../../assets/downloadIcon.png" @click="downloadExcel" slot="reference"
                     style="width: 14px;cursor: pointer">
              </el-popover>
              <div class="flexAndCenter">
                <input type="checkbox" style="margin-left: 20px" @change="allChecked" v-model="checked"/>
                <div class="fontSize14 color333" style="margin-left:10px">{{ $fanyi("全选") }}</div>
              </div>
              <div class="flexAndCenter margin-left-20">{{ $fanyi("已选择") }}
                <span class="colorB4272B fontWeightBold">{{ total }}</span>
                {{ $fanyi("件") }}
              </div>
              <div class="flexAndCenter margin-left-20">合計商品代金：
                <span class="colorB4272B fontWeightBold">{{ $fun.yenNumSegmentation(goodsPrice) }}</span>&nbsp;円
              </div>
              <div class="flexAndCenter margin-left-20">发送可能数：
                <span class="colorB4272B fontWeightBold">{{ goodsCount }}</span>&nbsp;{{ $fanyi("件") }}
              </div>
            </div>
            <div class="submitDeliveryOrderBtn" @click="deliveryPorderPreview">{{
                $fanyi("提出配送单")
              }}
            </div>
          </div>
        </div>
        <div style="margin: 0 26px">
          <ul class="tableHeader flexAndCenter">
            <li v-show="from.goods_status==='普通仓库'" class="checkAll">{{ $fanyi("选择") }}</li>
            <li class="number">{{ $fanyi("番号") }}</li>
            <li class="mirror">{{ $fanyi("照片") }}</li>
            <li class="newGoodsTitle">{{ $fanyi("商品规格") }}</li>
            <li class="goodsAttribute">{{ $fanyi("购入数据") }}</li>
            <li :class="from.goods_status==='普通仓库'?'remark':'newRemark'">{{ $fanyi("備考欄") }}</li>
            <li :class="from.goods_status==='普通仓库'?'remark':'newRemark'">{{ $fanyi("スタッフ") }}</li>
            <li :class="from.goods_status==='普通仓库'?'option':'newOption'">{{ $fanyi("附加服务") }}</li>
            <li class="tag">{{ $fanyi("标签设置") }}</li>
            <li v-if="from.goods_status==='普通仓库'" class="goodsProperty">{{ $fanyi("已提出数") }}</li>
            <li class="quantityOnHand" style="border-top-right-radius:10px;height: 56px;"
                :style="from.goods_status==='普通仓库'?'':'width: 250px'">
              {{ from.goods_status === '普通仓库' ? $fanyi("发送可能数") : $fanyi("装箱数量") }}
            </li>
          </ul>
        </div>
      </div>
      <!--      表格表头-->
      <div class="table">
        <div ref="contentBox" class="content-box">
          <div
              v-for="(bitem, bindex) in from.goods_status==='普通仓库'?stockAutoList:from.goods_status==='收费仓库'?stockFeeList:stockEncasementList"
              :key="bindex">
            <div v-show="from.goods_status==='收费仓库'" class="flexAndCenter boxMessageContainer">
              <div class="fontWeightBold" style="margin-right:101px">{{ $fanyi("箱号") }}：{{ bitem.box_no }}</div>
              <div class="flexAndCenter fontWeightBold">{{ $fanyi("实重") }}：
                <div class="boxMessageValue">{{ bitem.box_weight }}</div>
                kg
              </div>
              <div class="flexAndCenter margin-left-60 fontWeightBold">{{ $fanyi("体积") }}：
                <div class="boxMessageValue">{{ bitem.box_c * bitem.box_k * bitem.box_g }}</div>
                m³
              </div>
              <div class="flexAndCenter margin-left-60 fontWeightBold">{{ $fanyi("已产生费用") }}：
                <div class="boxMessageValue">{{ bitem.amount }}</div>
                {{ $fanyi("元") }}
              </div>
              <div class="flexAndCenter margin-left-60 fontWeightBold">{{ $fanyi("计费天数") }}：
                <div class="boxMessageValue">{{ bitem.amount_day }}</div>
                {{ $fanyi("天") }}
              </div>
            </div>
            <div v-if="from.goods_status==='装箱中'" class="tollWarehouseContainer">
              <div class="goodsConUlContainer">
                <div class="shopDetailContainer flexAndCenter">
                  <div class="flexAndCenter">
                    <div class="fontSize14 color333 fontWeightBold flex" style="margin-left:10px">
                      {{ $fanyi("箱号") }}：
                      <div class="colorB4272B" style="text-decoration: underline;margin-right: 20px">{{
                          bitem.box_no
                        }}
                      </div>
                      {{ $fanyi("订单号") }}：
                      <router-link target="_blank" class="colorB4272B cursorPointer"
                                   style="text-decoration: underline;"
                                   :to="{path:'/OrderDetails',query: {order_sn: bitem.order_sn}}">
                        {{ bitem.order_sn }}
                      </router-link>
                    </div>
                  </div>
                  <div class="showStatusBtn" @click="bitem.showStatus=!bitem.showStatus;$forceUpdate()">
                    <i :class="bitem.showStatus === true?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
                    <div>{{ bitem.showStatus === true ? $fanyi("隐藏") : $fanyi("展开") }}</div>
                  </div>
                </div>
                <ul v-for="item in bitem.order_box_detail" :key="item.id"
                    class="goodsConUl flexAndCenter">
                  <!--                    番号-->
                  <li class="number">
                    {{ item.order_detail.sorting }}
                  </li>
                  <!--                    写真-->
                  <li class="mirror">
                    <el-popover placement="right" trigger="hover">
                      <img :src="item.order_detail.pic"
                           style="width: 300px; height: 300px"/>
                      <img slot="reference" :src="item.order_detail.pic" style="width: 60px;height: 60px"/>
                    </el-popover>
                  </li>
                  <!--                    商品规格-->
                  <li class="newGoodsTitle">
                    <el-popover trigger="hover">
                      <div class="showDetailCon u-line">
                        <p v-for="(bitem, bindex) in item.order_detail.detail"
                           :key="bitem.key + bindex"
                           :title="bitem.key + ':' + bitem.value"
                           class="goodsDetailOpt ispay">
                          {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                        </p>
                      </div>
                      <div slot="reference" class="showDetailCon u-line">
                        <div class="flex" style="justify-content: center;flex-direction: column">
                          <p v-for="(bitem, bindex) in item.order_detail.detail"
                             :key="bitem.key + bindex"
                             :title="bitem.key + ':' + bitem.value"
                             class="goodsDetailOpt ispay">
                            {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                          </p>
                        </div>
                      </div>
                    </el-popover>
                  </li>
                  <!--                    小计-->
                  <li class="goodsAttribute flexAndCenter">
                    <div class="newRemarkData">
                      <div>{{ $fanyi("数量") }}：{{ item.order_detail.confirm_num }}</div>
                      <div>{{ $fanyi("单价") }}：{{ item.order_detail.confirm_price }}{{ $fanyi("元") }}</div>
                      <div>{{ $fanyi("国内运费") }}：{{ item.order_detail.confirm_freight }}{{ $fanyi("元") }}</div>
                      <div>{{ $fanyi("小计") }}：
                        {{
                          (Number(item.order_detail.confirm_num) * Number(item.order_detail.confirm_price)).toFixed(2)
                        }}{{ $fanyi("元") }}
                      </div>
                    </div>
                  </li>
                  <!--                    備考欄-->
                  <li :class="from.goods_status==='普通仓库'?'remark flexAndCenter':'newRemark flexAndCenter'">
                    <el-input v-model="item.order_detail.client_remark" readonly resize="none"
                              type="textarea"/>
                  </li>
                  <!--                    スタッフ-->
                  <li :class="from.goods_status==='普通仓库'?'remark flexAndCenter':'newRemark flexAndCenter'">
                    {{ item.order_detail.y_reply }}
                  </li>
                  <!--                    option-->
                  <li :class="from.goods_status==='普通仓库'?'option':'newOption'"
                      style="flex-direction: column;align-items: normal">
                    <div class="optionHiddenContainer" @click="getOptionMessage(item)">
                      <div v-for="(optionItem,optionIndex) in item.order_detail.option" :key="optionIndex"
                           style="text-align: left;width:149px;cursor: pointer">
                        <div v-if="optionItem.num>0&&optionItem.checked===true">
                          {{ optionIndex + 1 }}.{{ optionItem.name_translate }} *{{ optionItem.num }}
                        </div>
                      </div>
                    </div>
                    <div v-show="item.optionCheckedNum > 4" style="text-align: left;width:149px;">
                      ........
                    </div>
                  </li>
                  <!--                    标签设置-->
                  <li class="tag">
                    <el-popover trigger="hover">
                      <div class="orderTagPopoverShowContainer">
                        <div v-for="(tagItem,tagIndex) in item.order_detail.order_detail_tag" :key="tagIndex"
                             class="tagList">
                          <div v-show="tagItem.no!==''||tagItem.goods_no!==''">
                            <div class="color333 fontSize14">{{ tagItem.type_translate }}</div>
                            <div class="color333 fontSize14">{{ tagItem.no }}</div>
                            <div class="color333 fontSize14">{{ tagItem.goods_no }}</div>
                          </div>
                        </div>
                      </div>
                      <div slot="reference" class="orderTagContainer">
                        <div v-for="(tagItem,tagIndex) in item.order_detail.order_detail_tag" :key="tagIndex"
                             class="tagList"
                             style="width: 125px">
                          <div v-show="tagItem.no!==''||tagItem.goods_no!==''">
                            <div class="color333 fontSize14">{{ tagItem.type_translate }}</div>
                            <div class="color333 fontSize14" style="word-wrap: break-word">{{ tagItem.no }}</div>
                            <div class="color333 fontSize14" style="word-wrap: break-word">{{
                                tagItem.goods_no
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-popover>
                  </li>
                  <!--                    已提出数-->
                  <li v-if="from.goods_status==='普通仓库'" class="goodsProperty">{{
                      item.order_detail.send_await_num
                    }}
                  </li>
                  <!--                    可提出数-->
                  <li :style="from.goods_status==='普通仓库'?'':'width: 250px'" class="quantityOnHand">
                    {{ item.order_detail.packing_quantity }}
                  </li>
                </ul>
              </div>
            </div>
            <div v-else>
              <div class="shopDetailContainer flexAndCenter">
                <div class="flexAndCenter">
                  <input type="checkbox" style="margin-left: 20px"
                         @change="shopAll(bindex)"
                         v-model="bitem.checked"/>
                  <div class="fontSize14 color333 fontWeightBold" style="margin-left:10px">
                    {{ $fanyi("订单号") }}：
                    <router-link target="_blank" class="colorB4272B cursorPointer" style="text-decoration: underline;"
                                 :to="{path:'/OrderDetails',query: {order_sn: bitem.order_sn}}">
                      {{ bitem.order_sn }}
                    </router-link>
                  </div>
                  <el-popover v-if="!$fun.isArray(bitem.children_user)" placement="top" trigger="hover">
                    <div>
                      <div>社員用アカウント情報</div>
                      <div>氏名：{{ bitem.children_user.children_realname }}</div>
                    </div>
                    <img slot="reference"
                         class="cursorPointer"
                         src="../../../../assets/subAccount.png"
                         style="margin-top: 2px;margin-left: 5px"
                    />
                  </el-popover>
                </div>
                <el-popover v-if="bitem.follow_remark!=null&&bitem.follow_remark!=''" placement="bottom"
                            trigger="hover">
                  <div>
                    注文書管理用備考欄：{{ bitem.follow_remark }}
                  </div>
                  <div slot="reference" class="u-line fontSize14" style="width: 900px">
                    注文書管理用備考欄：{{ bitem.follow_remark }}
                  </div>
                </el-popover>
                <div class="showStatusBtn" @click="bitem.showStatus=!bitem.showStatus;$forceUpdate()">
                  <i :class="bitem.showStatus === true?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
                  <div>{{ bitem.showStatus === true ? $fanyi("隐藏") : $fanyi("展开") }}</div>
                </div>
              </div>
              <div class="goodsConUlContainer" v-show="bitem.showStatus===true">
                <div v-if="bitem.arr!=undefined"
                     style="border-radius: 10px;border:1px solid #FFCF96;overflow: hidden;position: relative">
                  <!-- 标签编辑 -->
                  <div class="tagContainer">
                    <div class="cursorPointer" @click="getGoodsTag(bitem)">
                      <el-popover trigger="hover">
                        <div class="orderTagPopoverShowContainer">
                          <div v-for="(tagItem,tagIndex) in bitem.order_detail_tag" :key="tagIndex" class="tagList">
                            <div>{{ tagItem.type_translate }}</div>
                            <div>{{ tagItem.no }}</div>
                            <div>{{ tagItem.goods_no }}</div>
                          </div>
                        </div>
                        <div slot="reference">
                          <div class="orderTagContainer" style="height: 78px;overflow: hidden">
                            <div v-for="(tagItem,tagIndex) in bitem.order_detail_tag" :key="tagIndex" class="tagList">
                              <div class="fontSize14 color333">{{ tagItem.type_translate }}</div>
                              <div class="fontSize14 color333" style="word-wrap: break-word">{{ tagItem.no }}</div>
                              <div class="fontSize14 color333" style="word-wrap: break-word">{{
                                  tagItem.goods_no
                                }}
                              </div>
                            </div>
                          </div>
                          <div v-show="bitem.order_detail_tag.length > 1" style="text-align: left;">
                            ........
                          </div>
                        </div>
                      </el-popover>
                    </div>
                  </div>
                  <div v-for="(item,itemIndex) in bitem.arr" :key="item.id">
                    <ul :style="item.set_detail_id<0?'background:#FFF0DE;':'background:#FFF8ED;'"
                        class="goodsConUl flexAndCenter">
                      <!--                    選択-->
                      <li class="checkAll">
                        <input v-model="item.checked" style="margin-left: 0"
                               type="checkbox"
                               @change="radio(bindex,itemIndex,'arr')"/>
                      </li>
                      <!--                    番号-->
                      <li class="number cursorPointer" style="margin-right: 5px" @click="getEditSnInfo(item)">
                        <div>
                          <div v-if="item.set_detail_id<0" class="setGoodsContainer">组套商品</div>
                          <div v-else class="inBundleGoods">
                            <img alt="" src="../../../../assets/link.svg">同捆物{{ itemIndex }}
                          </div>
                          <div style="margin-bottom:10px">{{ item.sorting }}</div>
                          <div v-if="item.id!=snInfo.id" :title="item.for_sn" class="u-line" style="width: 90px">
                            {{ item.for_sn }}
                          </div>
                          <el-input v-else v-model="item.for_sn" :placeholder="$fanyi('管理番号')" size="mini"
                                    @blur="updateForSn(item)"
                                    @input="$forceUpdate()"/>
                        </div>
                      </li>
                      <!--                    写真-->
                      <li class="mirror">
                        <el-popover placement="right" trigger="hover">
                          <img :src="item.pic"
                               style="width: 300px; height: 300px"/>
                          <img slot="reference" :src="item.pic"
                               style="width: 60px;height: 60px"
                               @click="$fun.toCommodityDetails(item.goods_id,item.from_platform)"/>
                        </el-popover>
                      </li>
                      <!--                    商品规格-->
                      <li class="newGoodsTitle">
                        <el-popover trigger="hover">
                          <div class="showDetailCon u-line">
                            <p v-for="(bitem, bindex) in item.detail"
                               :key="bitem.key + bindex"
                               :title="bitem.key + ':' + bitem.value"
                               class="goodsDetailOpt ispay">
                              {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                            </p>
                          </div>
                          <div slot="reference" class="showDetailCon u-line">
                            <div class="flex" style="justify-content: center;flex-direction: column">
                              <p v-for="(bitem, bindex) in item.detail"
                                 :key="bitem.key + bindex"
                                 :title="bitem.key + ':' + bitem.value"
                                 class="goodsDetailOpt ispay">
                                {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                              </p>
                            </div>
                          </div>
                        </el-popover>
                      </li>
                      <!--                    小计-->
                      <li class="goodsAttribute flexAndCenter">
                        <div class="newRemarkData">
                          <div>{{ $fanyi("数量") }}：{{ item.confirm_num }}</div>
                          <div>{{ $fanyi("单价") }}：{{ item.confirm_price }}{{ $fanyi("元") }}</div>
                          <div>{{ $fanyi("小计") }}：
                            {{
                              Number(Number(item.confirm_num) * Number(item.confirm_price)).toFixed(2)
                            }}{{ $fanyi("元") }}
                          </div>
                        </div>
                      </li>
                      <!--                    備考欄-->
                      <li :class="from.goods_status==='普通仓库'?'remark flexAndCenter':'newRemark flexAndCenter'">
                        <el-input v-model="item.client_remark" resize="none" type="textarea"
                                  @blur="updateClientRemark(item)"
                                  @input="updateInput"/>
                      </li>
                      <!--                    スタッフ-->
                      <li :class="from.goods_status==='普通仓库'?'remark flexAndCenter':'newRemark flexAndCenter'">
                        {{ item.y_reply }}
                      </li>
                      <!--                    option-->
                      <li :class="from.goods_status==='普通仓库'?'option':'newOption'"
                          style="flex-direction: column;align-items: normal">
                        <div class="optionHiddenContainer" @click="getOptionMessage(item)">
                          <div v-for="(optionItem,optionIndex) in item.option" :key="optionIndex">
                            <div v-if="optionItem.num>0&&optionItem.checked===true"
                                 :title="optionIndex + 1+'.'+optionItem.name_translate+' *'+optionItem.num"
                                 class="u-line" style="text-align: left;width:209px;cursor: pointer">
                              {{ optionIndex + 1 }}.{{ optionItem.name_translate }} *{{ optionItem.num }}
                            </div>
                          </div>
                        </div>
                        <div v-show="item.optionCheckedNum > 4" style="text-align: left;width:149px;">
                          ........
                        </div>
                      </li>
                      <li class="tag editTag"></li>
                      <!--                    已提出数-->
                      <li v-if="from.goods_status==='普通仓库'" class="goodsProperty">{{ item.send_success_num }}</li>
                      <!--                    可提出数-->
                      <li class="quantityOnHand" style="width: 90px">
                        {{ from.goods_status === '普通仓库' ? item.send_may_num : item.is_box_num }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div v-for="(item,itemIndex) in bitem.order_detail" :key="item.id">
                    <ul v-if="item.set_detail_id==0" class="goodsConUl flexAndCenter">
                      <!--                    選択-->
                      <li class="checkAll">
                        <input v-model="item.checked" style="margin-left: 0"
                               type="checkbox"
                               @change="radio(bindex,itemIndex,'order_detail')"/>
                      </li>
                      <!--                    番号-->
                      <li class="number cursorPointer" style="margin-right: 5px" @click="getEditSnInfo(item)">
                        <div>
                          <div style="margin-bottom:10px">{{ item.sorting }}</div>
                          <div v-if="item.id!=snInfo.id" :title="item.for_sn" class="u-line" style="width: 90px">
                            {{ item.for_sn }}
                          </div>
                          <el-input v-else v-model="item.for_sn" :placeholder="$fanyi('管理番号')" size="mini"
                                    @blur="updateForSn(item)"
                                    @input="$forceUpdate()"/>
                        </div>
                      </li>
                      <!--                    写真-->
                      <li class="mirror">
                        <el-popover placement="right" trigger="hover">
                          <img :src="item.pic"
                               style="width: 300px; height: 300px"/>
                          <img slot="reference" :src="item.pic"
                               style="width: 60px;height: 60px"
                               @click="$fun.toCommodityDetails(item.goods_id,item.from_platform)"/>
                        </el-popover>
                      </li>
                      <!--                    商品规格-->
                      <li class="newGoodsTitle">
                        <el-popover trigger="hover">
                          <div class="showDetailCon u-line">
                            <p v-for="(bitem, bindex) in item.detail"
                               :key="bitem.key + bindex"
                               :title="bitem.key + ':' + bitem.value"
                               class="goodsDetailOpt ispay">
                              {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                            </p>
                          </div>
                          <div slot="reference" class="showDetailCon u-line">
                            <div class="flex" style="justify-content: center;flex-direction: column">
                              <p v-for="(bitem, bindex) in item.detail"
                                 :key="bitem.key + bindex"
                                 :title="bitem.key + ':' + bitem.value"
                                 class="goodsDetailOpt ispay">
                                {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                              </p>
                            </div>
                          </div>
                        </el-popover>
                      </li>
                      <!--                    小计-->
                      <li class="goodsAttribute flexAndCenter">
                        <div class="newRemarkData">
                          <div>{{ $fanyi("数量") }}：{{ item.confirm_num }}</div>
                          <div>{{ $fanyi("单价") }}：{{ item.confirm_price }}{{ $fanyi("元") }}</div>
                          <div>{{ $fanyi("小计") }}：
                            {{
                              Number(Number(item.confirm_num) * Number(item.confirm_price)).toFixed(2)
                            }}{{ $fanyi("元") }}
                          </div>
                        </div>
                      </li>
                      <!--                    備考欄-->
                      <li :class="from.goods_status==='普通仓库'?'remark flexAndCenter':'newRemark flexAndCenter'">
                        <el-input v-model="item.client_remark" resize="none" type="textarea"
                                  @blur="updateClientRemark(item)"
                                  @input="updateInput"/>
                      </li>
                      <!--                    スタッフ-->
                      <li :class="from.goods_status==='普通仓库'?'remark flexAndCenter':'newRemark flexAndCenter'">
                        {{ item.y_reply }}
                      </li>
                      <!--                    option-->
                      <li :class="from.goods_status==='普通仓库'?'option':'newOption'"
                          style="flex-direction: column;align-items: normal">
                        <div class="optionHiddenContainer" @click="getOptionMessage(item)">
                          <div v-for="(optionItem,optionIndex) in item.option" :key="optionIndex">
                            <div v-if="optionItem.num>0&&optionItem.checked===true"
                                 :title="optionIndex + 1+'.'+optionItem.name_translate+' *'+optionItem.num"
                                 class="u-line" style="text-align: left;width:209px;cursor: pointer">
                              {{ optionIndex + 1 }}.{{ optionItem.name_translate }} *{{ optionItem.num }}
                            </div>
                          </div>
                        </div>
                        <div v-show="item.optionCheckedNum > 4" style="text-align: left;width:149px;">
                          ........
                        </div>
                      </li>
                      <li class="tag editTag" @click="getGoodsTag(item)">
                        <el-popover trigger="hover">
                          <div class="orderTagPopoverShowContainer">
                            <div v-for="(tagItem,tagIndex) in item.order_detail_tag" :key="tagIndex" class="tagList">
                              <div>{{ tagItem.type_translate }}</div>
                              <div>{{ tagItem.no }}</div>
                              <div>{{ tagItem.goods_no }}</div>
                            </div>
                          </div>
                          <div slot="reference">
                            <div class="orderTagContainer" style="height: 78px;overflow: hidden">
                              <div v-for="(tagItem,tagIndex) in item.order_detail_tag" :key="tagIndex" class="tagList">
                                <div>{{ tagItem.type_translate }}</div>
                                <div style="word-wrap: break-word">{{ tagItem.no }}</div>
                                <div style="word-wrap: break-word">{{ tagItem.goods_no }}</div>
                              </div>
                            </div>
                            <div v-show="item.order_detail_tag.length > 1" style="text-align: left;">
                              ........
                            </div>
                          </div>
                        </el-popover>
                      </li>
                      <!--                    已提出数-->
                      <li v-if="from.goods_status==='普通仓库'" class="goodsProperty">{{ item.send_success_num }}</li>
                      <!--                    可提出数-->
                      <li class="quantityOnHand" style="width: 90px">
                        {{ from.goods_status === '普通仓库' ? item.send_may_num : item.is_box_num }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="technicalServiceSupport">
      <div>1688 より、一部技術、情報サポートを受けて提供させて頂いております</div>
    </div>
    <!--    option费用明细-->
    <div class="goodsOptionListContainer">
      <el-dialog
          :title="$fanyi('オプション詳細')"
          :visible.sync="showGoodsOptionDialogVisible"
          width="520px"
          center>
        <div class="optionHead" style="margin-top: 20px">
          <ul class="flexAndCenter color333 fontSize14">
            <li class="optionSelect">{{ $fanyi("类型") }}</li>
            <li class="num">{{ $fanyi("数量") }}</li>
            <li class="num">{{ $fanyi("单价") }}</li>
            <li class="estimatedCost">{{ $fanyi("小计（元）") }}</li>
          </ul>
        </div>
        <!-- 单个商品 -->
        <div class="optionFooter">
          <div v-for="(listItem, listIndex) in optionList" :key="listIndex" class="flexAndCenter color333"
               style="background: transparent">
            <ul v-if="listItem.num>0&&listItem.checked===true" class="flexAndCenter color333"
                style="background: transparent">
              <li class="optionSelect" style="line-height: normal">{{ listIndex + 1 }}）{{
                  listItem.name_translate
                }}
              </li>
              <li v-if="listItem.price_type!=1" class="num">{{ listItem.num }}</li>
              <li :style="listItem.price_type==1?'max-width:100px;margin-left: 80px;':''" class="num">
                {{ listItem.num * Number(listItem.price) }}
              </li>
              <li class="estimatedCost">{{ listItem.total }}</li>
            </ul>
          </div>
          <div class="optionTotalPriceContainer flexAndCenter">
            <div class="color333">{{ $fanyi("合计（元）") }}：</div>
            <div>{{ goodsOptionTotalPrice.toFixed(2) }}</div>
          </div>
        </div>
      </el-dialog>
      <el-dialog
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :title="$fanyi('标签设置')"
          :visible.sync="selectTagDialogVisible"
          center
          width="1030px">
        <editTag :key="timer" ref="editTag" :fullscreenLoading="newFullscreenLoading"
                 :isShow="account_authority"
                 :goodsInfo="goodsInfo" :list="orderTagList" @saveGoodsTag="saveGoodsTag"/>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import HomePageTop from "../../../../components/head/HomePageTop.vue";
import editTag from "@/views/UserCenter/views/userOrder/views/components/editTag.vue";
import websiteUrl from "@/api/wangZhi";

export default {
  data() {
    return {
      addTagTxt: "", //添加标签的文本
      loading: true,
      checked: false,
      timer: null,
      showGoodsOptionDialogVisible: false,
      selectTagDialogVisible: false,
      newFullscreenLoading: false,
      stockEncasementList: [],
      from: {
        goods_status: '普通仓库',
        page: 1,
        pageSize: 10,
        keywords: '',
        for_sn: '',
        tag: ''
      },
      addressSwitchover: [{
        label: this.$fanyi("普通仓库") + '（0）',
        value: '普通仓库'
      }, {
        label: this.$fanyi("收费仓库") + '（0）',
        value: '收费仓库'
      }],
      goodsPrice: 0,
      goodsCount: 0,
      optionList: [],
      goodsOptionTotalPrice: 0,
      account_authority: false,
      orderTagList: [],
      goodsInfo: {},
      snInfo: {},
      switchoverIndex: 0,
      total: 0,
      stockFeeList: [],
      stockAutoList: [],
      children_id: '',
      employeesAccountList: [],
    };
  },
  components: {
    editTag,
    HomePageTop,
  },
  computed: {
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate');
      return Number(exchangeRate)
    }
  },
  mounted() {
    this.from.pageSize = 10;
    if (this.$route.query.status) {
      switch (this.$route.query.status) {
        case 'auto':
          this.from.status = '普通仓库';
          this.switchoverIndex = 0;
          break;
        case 'fee':
          this.from.status = '收费仓库';
          this.switchoverIndex = 1;
          break;
      }
    } else {
      this.from.goods_status = '普通仓库'
    }
    let userData = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("userData"))));
    this.account_authority = userData.account_authority;
    this.$api.childrenUserList({
      page: 1,
      pageSize: 1000
    }).then((res) => {
      this.employeesAccountList = res.data.data;
    })
    if (userData.is_encasement_authorized != undefined && userData.is_encasement_authorized == 1) {
      this.addressSwitchover.push({
        label: this.$fanyi("装箱中") + '（0）',
        value: '装箱中'
      })
    }
    this.getStockFeeList();
    this.getStockAutoList();
    this.getStockEncasementList();
    this.warehouseSum();
  },
  methods: {
    //  收费仓库列表
    getStockFeeList() {
      this.total = 0;
      this.$api.stockFeeList({
        page: 1,
        pageSize: 1000
      }).then((res) => {
        if (res.code != 0) return;
        let count = 0;
        res.data.forEach((item) => {
          item.box_c = item.box_c / 100;
          item.box_k = item.box_k / 100;
          item.box_g = item.box_g / 100;
          item.order.forEach((items) => {
            items.checked = false;
            items.order_detail.forEach((itemss) => {
              count++;
              itemss.optionCheckedNum = 0;
              itemss.checked = false;
              if (itemss.option != null) {
                itemss.option.forEach((optionItem) => {
                  if (optionItem.num > 0 && optionItem.checked === true) {
                    itemss.optionCheckedNum++
                  }
                })
              }
            })
          })
        })
        this.stockFeeList = Object.freeze(res.data);
      });
    },
    // 普通仓库列表
    getStockAutoList() {
      this.total = 0;
      this.goodsPrice = 0;
      this.goodsCount = 0;
      this.$api.stockAutoList({
        page: 1,
        pageSize: 1000,
        children_id: this.children_id
      }).then((res) => {
        if (res.code != 0) return;
        let count = 0;
        res.data.forEach((item, index) => {
          item.checked = false;
          item.showStatus = true;
          let detailArr = [];
          let detailArr2 = [];
          item.order_detail.forEach((items, itemsIndex) => {
            items.checked = false;
            count++;
            items.optionCheckedNum = 0;
            if (items.option != null) {
              items.option.forEach((optionItem) => {
                if (optionItem.num > 0 && optionItem.checked === true) {
                  items.optionCheckedNum++
                }
              })
            }
            if (items.set_detail_id < 0) {
              let arr = [];
              arr.push(this.$fun.deepClone(items));
              item.arr = arr;
              item.order_detail_tag = this.$fun.deepClone(items.order_detail_tag);
              item.id = items.id;
              if (detailArr2.length == 0) {
                detailArr.push({
                  index: index,
                  goodsItemIndex: itemsIndex,
                  detailId: items.id,
                });
              } else {
                detailArr2.forEach((arrItem) => {
                  if (arrItem.detailId == items.id) {
                    items.arr.push(arrItem.info)
                  }
                })
              }
            }
            if (items.set_detail_id > 0) {
              if (detailArr.length > 0) {
                detailArr.forEach((arrItem) => {
                  if (arrItem.detailId == items.set_detail_id) {
                    item.arr.push(this.$fun.deepClone(items));
                    this.$forceUpdate();
                  }
                })
              } else {
                detailArr2.push({
                  index: index,
                  goodsItemIndex: itemsIndex,
                  detailId: items.set_detail_id,
                  info: items
                });
              }
            }
          })
        })
        this.stockAutoList = Object.freeze(res.data);
      });
    },
    //装箱中仓库列表
    getStockEncasementList() {
      this.$api.encasementList().then((res) => {
        if (res.code != 0) return;
        res.data.forEach((item) => {
          item.showStatus = true;
          item.order_box_detail.forEach((box_detail_item) => {
            box_detail_item.optionCheckedNum = 0;
            if (box_detail_item.order_detail.option != null) {
              box_detail_item.order_detail.option.forEach((optionItem) => {
                if (optionItem.num > 0 && optionItem.checked === true) {
                  box_detail_item.optionCheckedNum++
                }
              })
            }
          })
        })
        this.stockEncasementList = Object.freeze(res.data);
      });
    },
    warehouseSum() {
      this.$api.stockTableNum().then((res) => {
        res.data.forEach((item) => {
          switch (item.name) {
            case '普通仓库':
              this.addressSwitchover[0].label = this.$fanyi("普通仓库") + `（${item.count}）`;
              break;
            case '收费仓库':
              this.addressSwitchover[1].label = this.$fanyi("收费仓库") + `（${item.count}）`;
              break;
            case '装箱中':
              this.addressSwitchover.length > 2 ? this.addressSwitchover[2].label = this.$fanyi("装箱中") + `（${item.count}）` : '';
              break;
          }
        })
      })
    },
    //订单下的商品全选或取消全选
    shopAll(bindex) {
      this.total = 0;
      let orderCount = 0;
      let status = false;
      this.stockAutoList.forEach((item, itemIndex) => {
        if (item.checked) {
          orderCount++
        }
        let arr = ['order_detail', 'arr'];
        arr.forEach((val) => {
          if (item[val] != undefined) {
            item[val].forEach((items) => {
              if (items.send_await_num === 0) {
                items.checked = false;
                orderCount--
                if (items.checked === true) {
                  status = true;
                }
              } else {
                if (itemIndex === bindex) {
                  items.checked = item.checked
                }
                if (items.checked) {
                  this.total++
                }
                this.$forceUpdate();
              }
            })
          }
        })
        this.checked = itemIndex === this.stockAutoList.length - 1 && orderCount === this.stockAutoList.length;
      })
      if (status === true) {
        this.$message.error(this.$fanyi("部分商品已打包装箱，不可手动提出，请联系您的负责人进行了解"));
      }
      this.commodityTotal();
    },
    //获取要编辑的管理番号详情
    getEditSnInfo(val) {
      this.snInfo = val;
    },
    updateForSn(val) {
      if (val.for_sn != '') {
        this.$api.updateOrderDetailForSn({order_detail_id: val.id, for_sn: val.for_sn}).then((res) => {

        });
      }
    },
    commodityTotal() {
      this.goodsPrice = 0;
      this.goodsCount = 0;
      this.stockAutoList.forEach((item) => {
        let arr = ['order_detail', 'arr'];
        arr.forEach((val) => {
          if (item[val] != undefined) {
            item[val].forEach((items) => {
              if (items.checked) {
                this.goodsPrice += items.send_may_num * Number(items.confirm_price);
                this.goodsCount += items.send_may_num;
              }
            })
          }
        })
      })
      this.goodsPrice = (this.goodsPrice * Number(this.exchangeRate)).toFixed(0);
    },
    //仓库切换
    switchoverWarehouse(item, index) {
      this.switchoverIndex = index;
      this.from.goods_status = item.value;
      this.$forceUpdate();
    },
    //单选处理
    radio(index, index1, arrName) {
      if (arrName === 'order_detail' && this.stockAutoList[index].order_detail[index1].send_await_num === 0) {
        this.stockAutoList[index].order_detail[index1].checked = false;
        this.$message.error(this.$fanyi("部分商品已打包装箱，不可手动提出，请联系您的负责人进行了解"));
        return
      }
      if (arrName === 'arr' && this.stockAutoList[index].arr[index1].send_await_num === 0) {
        this.stockAutoList[index].arr[index1].checked = false;
        this.$message.error(this.$fanyi("部分商品已打包装箱，不可手动提出，请联系您的负责人进行了解"));
        return
      }
      this.total = 0;
      let orderCount = 0;//订单选中数量
      this.stockAutoList.forEach((item) => {
        let count = 0;//商品选中数量
        let arr = ['order_detail', 'arr'];
        arr.forEach((val) => {
          if (item[val] != undefined) {
            item[val].forEach((items, itemsIndex) => {
              //如果商品被选中了商品选中数量增加
              //商品选中时总商品选中数量增加，否则减少
              if (items.checked === true) {
                this.total++
                count++
              }
              //如果到最后一条数据时商品选中数量等于订单数组的数据，就选中店铺全选
              item.checked = count === item[val].length && itemsIndex === item[val].length - 1;
              if (item.checked === true) {
                orderCount++
              }
              this.checked = orderCount === this.stockAutoList.length;
            })
          }
        })
      })
      this.commodityTotal();
    },
    downloadExcel() {
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi("数据导出中"),
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
      var xhr = new XMLHttpRequest();
      let url = websiteUrl + '/client/wms.warehouseInvoice';
      xhr.open("post", url, true);
      xhr.responseType = "blob";
      xhr.setRequestHeader('accept', '*/*');
      xhr.setRequestHeader('accept-language', 'zh-CN');
      xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      let user_token = localStorage.getItem('japan_user_token')
      xhr.setRequestHeader("ClientToken", 'Bearer ' + user_token);
      let that = this;
      xhr.onload = function () {
        if (this.status == 200 && this.response.type != "text/html") {
          if (window.navigator.msSaveOrOpenBlob) { //IE浏览器
            navigator.msSaveBlob(this.response, `倉庫エクスポート`);
          } else {//非IE浏览器
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(this.response);
            link.download = `倉庫エクスポート`;
            link.click();
            window.URL.revokeObjectURL(link.href);
            that.loading.close();
            that.$forceUpdate();
          }
        } else {
          console.log("响应出错啦!");
        }
      };
      xhr.send();
    },
    updateClientRemark(item) {
      this.$api.updateStockClientRemark({
        order_detail_id: item.id,
        client_remark: item.client_remark
      }).then(() => {
      })
    },
    //获取商品tag
    getGoodsTag(item) {
      this.goodsInfo = item;
      this.selectTagDialogVisible = true;
      this.$api.getOrderTagList({
        order_detail_id: item.id
      }).then((res) => {
        let list = [];
        res.data.forEach((item) => {
          list.push({
            value: item.type,
            label: item.type_translate
          })
        })
        if (item.order_detail_tag == undefined || item.order_detail_tag.length === 0) {
          let order_detail_tag = [];
          order_detail_tag.push({
            no: '',
            goods_no: '',
            list: list,
            type: ''
          })
          setTimeout(() => {
            this.newFullscreenLoading = false;
            this.orderTagList = this.$fun.deepClone(order_detail_tag);
            this.$refs.editTag.value = order_detail_tag[0].id
          }, 500)
        } else {
          item.order_detail_tag.forEach((items) => {
            items.list = list
          })
          this.orderTagList = this.$fun.deepClone(item.order_detail_tag);
          setTimeout(() => {
            this.newFullscreenLoading = false;
          }, 500)
        }
      });
    },
    //保存商品的标签编号
    saveGoodsTag(msg) {
      let arr = [];
      msg.forEach((item) => {
        if (item.no !== '' || item.goods_no !== '') {
          arr.push({
            type: item.type,
            no: item.no,
            goods_no: item.goods_no
          })
        }
      })
      this.stockAutoList.forEach((item) => {
        if (item.order_detail_tag != undefined) {
          if (this.goodsInfo.id === item.id) {
            item.order_detail_tag = msg;
          }
        } else {
          item.order_detail.forEach((detailItem) => {
            if (this.goodsInfo.id === detailItem.id) {
              detailItem.order_detail_tag = msg;
            }
          })
        }
      })
      if (arr.length > 0) {
        this.$api.updateStockOrderDetailTag({
          order_detail_id: this.goodsInfo.id,
          tags: arr
        }).then((res) => {
          if (res.code != 0) return this.$message.error(res.msg);
          this.selectTagDialogVisible = false;
        })
      }
    },
    updateInput() {
      this.$forceUpdate();
    },
    //获取商品option信息
    getOptionMessage(item) {
      this.optionList = [];
      this.goodsOptionTotalPrice = 0;
      item.option.forEach((optionItem) => {
        if ((Number(optionItem.num) > 0 && optionItem.checked === true) || (optionItem.price_type === 1 && optionItem.checked === true)) {
          if (optionItem.price_type === 1) {
            optionItem.total = (item.confirm_price * Number(item.confirm_num)) * (Number(optionItem.price) / 100);
            this.goodsOptionTotalPrice += (item.confirm_price * Number(item.confirm_num)) * (Number(optionItem.price) / 100);
          }
          if (optionItem.price_type === 0) {
            optionItem.total = Number(optionItem.num) * Number(optionItem.price);
            this.goodsOptionTotalPrice += Number(optionItem.num) * Number(optionItem.price);
          }
        }
      })
      this.optionList = this.$fun.deepClone(item.option);
      this.showGoodsOptionDialogVisible = true;
    },
    //全选处理
    allChecked() {
      let status = false;
      this.total = 0;
      this.stockAutoList.forEach((item) => {
        item.checked = this.checked;
        let arr = ['order_detail', 'arr'];
        arr.forEach((val) => {
          if (item[val] != undefined) {
            item[val].forEach((items) => {
              if (items.send_await_num === 0) {
                status = true;
              } else {
                items.checked = this.checked
                this.$forceUpdate();
              }
              if (items.checked) {
                this.total++
              }
            })
          }
        })
      })
      if (status === true && this.checked === true) {
        this.$message.error(this.$fanyi("部分商品已打包装箱，不可手动提出，请联系您的负责人进行了解"));
      }
      this.commodityTotal();
    },
    //获取选中的商品信息，跳转到提出配送单预览页面
    deliveryPorderPreview() {
      if (this.stockAutoList.length == 0) {
        return this.$message.error(this.$fanyi("请至少添加一个商品"));
      }
      let id = '';
      let newArr = [];
      let status = false;
      this.stockAutoList.forEach((item) => {
        let arr = ['order_detail', 'arr'];
        arr.forEach((val) => {
          if (item[val] != undefined) {
            item[val].forEach((items) => {
              if (items.checked === true) {
                id += items.id + ','
                if (items.send_await_num === 0) {
                  status = true;
                } else {
                  newArr.push({
                    order_detail_id: items.id
                  })
                }
              }
            })
          }
        })
      })
      if (status === true) {
        this.$message.error(this.$fanyi("部分商品已打包装箱，不可手动提出，请联系您的负责人进行了解"));
        return
      }
      if (newArr.length > 200) {
        this.$message.error(this.$fanyi("一个订单最多添加200个商品"));
        return
      }
      id = id.substr(0, id.length - 1);
      if (newArr.length > 0 || id !== '') {
        this.$store.commit("saveAutoWarehouseId", {arr: newArr});
        let href = `/deliveryDetails?id=${id}&type=temporary`;
        window.open(href, "_blank");
      }
    }
  },
};
</script>
<style lang="scss" scoped>
#userOrder {
  @import "../../../../css/mixin";
  width: 100%;

  .technicalServiceSupport {
    margin: 30px 0;
    display: flex;
    justify-content: center;

    div {
      width: 600px;
      height: 40px;
      background: #FFFFFF;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      color: #333333;
      line-height: 40px;
      font-size: 16px;
      text-align: center;
    }
  }

  /deep/ input[type="checkbox"]:checked {
    background: #4A91E9;
  }

  .tag {
    width: 160px;
  }

  .tagList {
    width: 160px;

    div {
      width: 160px;
      text-align: left;
      line-height: 20px;
      padding-left: 10px;
    }
  }

  .margin-left-60 {
    margin-left: 60px;
  }

  .margin-left-20 {
    margin-left: 20px;
  }

  /deep/ .el-dialog__header, /deep/ .el-dialog__body {
    padding: 0;
  }

  /deep/ .el-dialog {
    border-radius: 10px;
  }

  .submitDeliveryOrderBtn {
    height: 34px;
    background: #B4272B;
    border-radius: 8px;
    line-height: 34px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    padding: 0 30px;
    margin-left: 10px;
  }

  .tableHeader {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #F0F0F0;
    height: 56px;
    font-size: 14px;
    line-height: 56px;
    text-align: center;
    color: #333;
  }

  .checkAll {
    width: 50px;
  }

  .number {
    width: 100px;
  }

  .mirror {
    width: 66px;
  }

  .newGoodsTitle {
    width: 260px;

    .u-line-3 {
      width: 243px;
      line-height: 20px;
    }

    .attributeContainer {
      padding: 0 20px;

    }

    .newRemarkData {
      padding: 29px 0;

      div {
        text-align: left;
        color: #333333;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .goodsTitle {
    width: 220px;

    div {
      width: 193px;
      line-height: 20px;
      text-align: left;
    }
  }

  .goodsAttribute {
    width: 160px;
    justify-content: center;

    .attributeContainer {
      padding: 0 20px;

    }

    .newRemarkData {
      padding: 29px 0;

      div {
        text-align: left;
        color: #333333;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .editTag {
    height: 100px;
    border: 1px solid #DCDFE6;
    background: #fff !important;
    cursor: pointer;
    border-radius: 4px;
    overflow: hidden;
    padding-left: 10px;
  }

  .newRemark {
    width: 230px;

    /deep/ .el-textarea {
      display: flex;
      align-items: center;
      justify-content: center;

      .el-textarea__inner {
        width: 190px;
        height: 100px;
      }
    }
  }

  .remark {
    width: 160px;

    /deep/ .el-textarea {
      display: flex;
      align-items: center;
      justify-content: center;

      .el-textarea__inner {
        width: 120px;
        height: 100px;
      }
    }
  }

  .option {
    width: 210px;
  }

  .newOption {
    width: 270px;
  }

  .goodsProperty {
    width: 100px;
  }

  .quantityOnHand {
    width: 90px;
    background: #F8E2E2 !important;
    height: 140px;
  }


  .collectionContainer {
    width: 1400px;
    background: #FFFFFF;
    border-radius: 10px;
    margin: 30px auto 0;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 73px;
      padding: 0 26px;
      width: 1400px;

      span {
        font-size: 16px;
        font-weight: 400;
        font-family: "Open Sans", Sans-serif;
        color: #2f2f2f;
        line-height: 16px;

        .headT1 {
          display: block;
          color: #333333;
          font-size: 20px;
          font-weight: 600;
        }
      }

      .headerSearchContainer {
        height: 34px;
        border-radius: 8px;
        justify-content: space-between;
        box-sizing: border-box;

        .inputContainer {
          justify-content: center;

          /deep/ .el-input__inner {
            height: 34px;
            border: 1px solid #DFDFDF;
            font-size: 14px;
          }

          ::-webkit-input-placeholder { /*Webkit browsers*/
            color: #C6C6C6;
            font-size: 14px !important;
          }

          :-moz-placeholder { /*Mozilla Firefox 4 to 8*/
            color: #C6C6C6;
            font-size: 14px !important;
          }


          :-ms-input-placeholder { /*Internet Explorer 10+*/
            color: #C6C6C6;
            font-size: 14px !important;
          }
        }

        .searchBtn {
          width: 79px;
          background: #B4272B;
          cursor: pointer;
          height: 34px;
          line-height: 34px;
          text-align: center;
          color: #fff;
          font-size: 14px;
          border-radius: 8px;
        }
      }

      .editOrder {
        height: 32px;
        padding: 0 8px;
        background: $homePageSubjectColor;
        border-radius: 16px;
        color: white;
        margin-left: 20px;
        line-height: 20px;
        font-size: 14px;
      }
    }

    .orderScreenContainer {
      padding: 26px 26px 0 26px;
      border-bottom: 1px solid #B4272B;

      .orderScreenLeft {
        div {
          width: 130px;
          height: 40px;
          border-radius: 10px 10px 0 0;
          text-align: center;
          line-height: 40px;
          cursor: pointer;
          font-size: 14px;
          border: 1px solid #B4272B;
          color: #B4272B;
          background: #fff;
          border-bottom: none;
        }

        .activeSwitchover {
          color: #fff;
          background: #B4272B;
        }
      }

      .orderScreenRight {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-left: 20px;
      }

      .orderScreenBtnListContainer {
        div {
          width: 100px;
          height: 34px;
          background: #B4272B;
          border-radius: 8px;
          line-height: 34px;
          text-align: center;
          color: #FFFFFF;
          font-size: 14px;
          cursor: pointer;
          margin-right: 10px;
        }

        div:nth-child(5n) {
          margin-right: 0;
        }
      }
    }

    .stickyContainer {
      position: sticky;
      top: 58px;
      left: 0;
      background: #fff;
      z-index: 10;
    }

    .table {
      padding: 0 26px 30px;


      .managementNumber {
        width: 120px;
      }

      .price {
        width: 100px;
      }

      .subtotal {
        width: 80px;
      }

      .optionHiddenContainer {
        height: 73px !important;
        overflow: hidden;
      }


      .goodsInWarehouseQuantity {
        width: 160px;
      }


      .tagSetting {
        width: 140px;
      }


      .operation {
        width: 112px;

        .btnList {
          padding: 0 15px;
          justify-content: space-between;

          div {
            text-decoration: underline;
            font-size: 14px;
            cursor: pointer;
          }

          div:first-child {
            color: #B4272B;
          }

          div:last-child {
            color: #2742B4;
          }
        }
      }


      //.content-box::-webkit-scrollbar {
      //  display: none; /* Chrome Safari */
      //}

      .tableFooter {
        border-bottom: 1px solid #DFDFDF;
        height: 120px;
        font-size: 14px;
        line-height: 120px;
        text-align: center;
        color: #333;
      }

      .boxMessageContainer {
        height: 60px;

        .boxMessageValue {
          color: #B4272B;
          margin-right: 4px;
        }
      }

      .tollWarehouseContainer {
        border-radius: 10px;
        overflow: hidden;
      }

      .shopDetailContainer {
        height: 60px;
        min-width: 60px;
        background-color: #fff;
        justify-content: space-between;
      }

      .showStatusBtn {
        width: 90px;
        height: 30px;
        background: #F0F0F0;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
        cursor: pointer;
        margin-bottom: 10px;

        .el-icon-arrow-up:before, .el-icon-arrow-down:before {
          font-weight: bold;
          font-size: 14px;
        }

        div {
          color: #333333;
          font-size: 14px;
        }
      }

      .goodsConUlContainer {
        border-radius: 10px;
        overflow: hidden;
      }

      .goodsConUl {
        //height: 120px;
        background: #F9F9F9;
        width: 100%;
        min-height: 120px;
        height: 100%;

        li {
          text-align: center;
          font-size: 14px;
          color: #333;
          display: flex;
          align-items: center;
          justify-content: center;
          //line-height: 120px;
        }

        .setGoodsContainer {
          width: 68px;
          height: 28px;
          background: #FFDFB8;
          border-radius: 4px;
          font-size: 12px;
          color: #F08804;
          text-align: center;
          line-height: 28px;
          margin-bottom: 10px;
        }

        .inBundleGoods {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          font-size: 12px;
          color: #F08804;

          img {
            width: 12px !important;
            height: 12px !important;
            margin-right: 6px;
            margin-left: 0 !important;
          }
        }

        .showDetailCon {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 160px;

          p {
            width: 160px;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }

  .operatingOptions {
    width: $pageWidth;
    height: 70px;
    position: sticky;
    margin: 20px auto;
    right: 0;
    left: 0;
    z-index: 10;
    bottom: 0;
    border: 1px solid #e8e8e8;
    // height: 106px;
    background: #ffffff;
    box-shadow: 0 0 20px 0 rgba(10, 11, 34, 0.05);
    border-radius: 10px 10px 0 0;
    padding: 10px 26px 10px 0;
    justify-content: space-between;

    .fontWeightBold {
      margin: 0 8px;
    }
  }


  .goodsOptionListContainer {
    /deep/ .el-dialog {
      border-radius: 10px;
      padding: 30px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      margin: 0 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .el-dialog__header {
        padding: 0;

        .el-dialog__title {
          font-size: 18px;
          font-weight: bold;
          color: #333;
          padding-bottom: 7px;
          border-bottom: 4px solid #B4272B;
        }

        .el-dialog__headerbtn {
          top: 30px;
          font-size: 19px;

          .el-dialog__close {
            color: #B4272b;
          }
        }
      }

      .el-dialog__body {
        padding: 0;
      }

      .el-dialog__footer {
        padding: 0;

        button {
          border-radius: 8px;
          height: 34px;
          width: 120px;
          line-height: 34px;
          padding: 0;
          font-size: 14px;
        }

        button:first-child {
          color: #B4272B;
          border: 1px solid #B4272B;
          margin-right: 26px;
        }

        .el-button + .el-button {
          margin-left: 0;
        }
      }
    }

    .optionTotalPriceContainer {
      margin-top: 16px;
      justify-content: flex-end;

      div:first-child {
        font-size: 18px;
      }

      div:last-child {
        color: #B4272B;
        font-size: 24px;
        font-weight: bold;
      }
    }

    .optionHead {
      ul {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background: #F0F0F0;
        height: 46px;

        li {
          text-align: center;
          line-height: 46px;
          font-size: 14px;
          color: #333;
        }

        .optionSelect {
          width: 200px;
          min-width: 200px;
          padding-left: 26px;
          text-align: left;
          box-sizing: border-box;
        }

        .newOptionSelect {
          min-width: 80px !important;
          width: 80px !important;
          max-width: 80px !important;
          padding-left: 0 !important
        }

        .num {
          width: 80px;
          max-width: 80px;
        }

        .estimatedCost {
          width: 100px;
          min-width: 100px;
        }
      }
    }

    .optionFooter {
      ul {
        height: 50px;
        border-bottom: 1px solid #DFDFDF;

        li {
          text-align: center;
          line-height: 50px;
          font-size: 14px;
          color: #333;
        }

        .optionSelect {
          width: 200px;
          padding-left: 26px;
          text-align: left !important;
          box-sizing: border-box;
          min-width: 200px;
        }

        .newOptionSelect {
          min-width: 80px !important;
          width: 80px !important;
          max-width: 80px !important;
          padding-left: 0 !important;
          text-align: center !important;
        }

        .num {
          width: 80px;
          max-width: 80px;
          color: #B4272B;
          text-decoration: underline;
        }

        .estimatedCost {
          width: 100px;
          min-width: 100px;
        }
      }
    }
  }
}

.tagContainer {
  position: absolute;
  left: 1036px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 144.27px;
  background: #FFEFDA;
}
</style>
